<template>
  <v-menu content-class="rounded form-select-menu" bottom left v-if="!!numberFilter">
    <template #activator="{ on, attrs }">
      <v-btn
        text
        outlined
        v-bind="attrs"
        v-on="on"
        :ripple="false"
        height="46"
        class="rounded textLink--text ml-5 ml-sm-8 mb-4 mb-sm-15"
      >
        {{ numberFilter.name }}
        <v-icon color="selectChevron" right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    
    <v-list width="245" class="grayBlack pa-0 number-filter">
      <v-list-item-group mandatory v-model="numberActive">
        <v-list-item
          v-for="(item, i) in filters"
          :key="i"
          :ripple="false"
        >
          <v-list-item-title class="font-normal textLink--text">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'MenuNumberFilter',
  inheritAttrs: false,
  props: {
  
  },
  data: () => ({
    numberActive: 0,
    filters: [
      { name: 'All', value: null },
      { name: 'Not in Number Pool', value: 'exceptPool' },
      { name: 'Number in Pool', value: 'inPool' },
      { name: 'No vendor', value: 'noPublisher' },
      { name: 'No campaign', value: 'noCampaign' },
    ],
    numberFilter: null
  }),
  mounted() {
    this.numberFilter = this.filters[0]
  },
  watch: {
    numberActive: {
      handler(active) {
        this.numberFilter = this.filters[active]
        this.$emit('change', this.numberFilter.value)
      }
    }
  }
}
</script>

<style lang="scss">
.number-filter {
  .v-list-item {
    &:hover {
      .v-list-item__title {
        color: #A0A0A0 !important;
      }
    }
    
    &:before {
      border-radius: 0;
    }
  }
  
  .v-list-item-group {
    .v-list-item--active {
      .v-list-item__title {
        color: #A0A0A0 !important;
      }
    }
  }
}
</style>
