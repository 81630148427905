<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="500"
    content-class="cardBg dialogBuyNumber pa-15 pa-sm-20"
    transition="scroll-y-transition"
    overlay-color="overlayColor"
  >
    <v-card class="cardBg" outlined>
      <v-form ref="form" v-model="validateForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-0 pr-sm-11 pb-16"
            cols="12" sm="5"
          >
            <span class="font-large-title text-uppercase grayLighten3--text">Edit number</span>
          </v-col>
          <v-col cols="12" sm="7"></v-col>
          
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-11"
            cols="12" sm="5"
          >
            <span class="font-normal nameField--text">Name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="editNumberName"
            ></portal-target>
          </v-col>
          <v-col cols="12" sm="7" class="mt-7 mt-sm-0">
            <FormField
              class="field-container"
              v-model="formData.name"
            >
              <template #message="{ key, message }">
                <portal to="editNumberName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
        </v-row>
        
        <v-card-actions class="justify-end pr-0 pt-0 mt-16">
          <ActionButton class="mr-5 mr-sm-10" :loading="loadingAction" @click="submitForm">Save</ActionButton>
          
          <CancelButton @click="closeDialog">Cancel</CancelButton>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

import { updateNumber, updateNumberForVendor } from '@/api/number-routes'

import { mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'DialogEditNumber',
  inheritAttrs: false,
  components: { FormField, ActionButton, CancelButton },
  props: {
    value:  {
      type: Boolean,
      default: false
    },
    editNumber: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    loadingAction: false,
    formData: {
      name: null,
    },
    validateForm: true,
    rules: [],
  }),
  computed: {
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
    }),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    parseProps() {
      this.formData.name = this.editNumber.name
    },
    async submitForm() {
      await this.validationForm()

      if (this.$refs.form.validate()) {
        this.loadingAction = true

        const { success, payload, message } = this.isManager
          ? await updateNumber({ numberId: this.editNumber.id, formData: this.formData })
          : await updateNumberForVendor({ numberId: this.editNumber.id, formData: this.formData })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('actionSuccess', payload)
          this.closeDialog()
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show: {
      handler(value) {
        if (value) {
          this.parseProps()
        } else {
          this.formData.name = null
          this.rules = []
        }
      },
    },
  },
}
</script>
