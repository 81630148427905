<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="575px"
    content-class="dialogConfirm"
    transition="scroll-y-transition"
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-25 py-sm-32" :class="isMobile ? 'px-10' : 'px-32'">
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column align-start align-sm-end justify-center pr-11 pb-2"
          cols="12" sm="5"
        >
          <div class="font-large-title text-uppercase dialogConfirmTitle--text">Attach to Pool</div>
        </v-col>
        <v-col cols="12" sm="7" class="pa-0 pa-sm-3"></v-col>
      </v-row>
      
      <v-form ref="form" class="pt-10 pt-sm-14 pb-0 pb-sm-3">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="5"
          >
            <span class="font-normal nameField--text">Select Pool</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="selectPool"
            ></portal-target>
          </v-col>
          <v-col class="pb-10" cols="12" sm="7">
            <AutocompleteField
              class="field-container"
              v-model="changePoolId"
              :items="pools"
              :loading="loading"
              item-value="id"
              item-text="name"
              :rules="rules"
              height="48"
            >
              <template #message="{ message }">
                <portal to="selectPool" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
          </v-col>
        </v-row>
      </v-form>
      
      <div class="d-flex justify-start justify-sm-end">
        <ActionButton
          class="mr-8 mr-sm-11"
          :loading="loadingAction"
          @click="submitForm"
        >
          Attach
        </ActionButton>
        
        <CancelButton @click="closeDialog">Cancel</CancelButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AutocompleteField from '@/components/select/AutocompleteField'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

import { getAllPools, attachNumbersToPool } from '@/api/pool-routes'

export default {
  name: 'DialogAttachNumbersToPool',
  inheritAttrs: false,
  components: { AutocompleteField, ActionButton, CancelButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    numberIds: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    loading: false,
    loadingAction: false,
    changePoolId: null,
    rules: [],
    pools: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 450
    },
  },
  methods: {
    async loadAllPools() {
      this.loading = true
      
      const { success, payload, message } = await getAllPools()
      
      if (success) {
        this.pools = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    async submitForm() {
      await this.validationForm()
      
      if (this.$refs.form.validate()) {
        this.loadingAction = true

        const { success, payload, message } = await attachNumbersToPool({
          'pool_id': this.changePoolId,
          numbers: this.numberIds
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('actionSuccess', payload)
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        
        this.loadingAction = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show: {
      handler(value) {
        if (value) {
          this.loadAllPools()
        } else {
          this.changePoolId = null
          this.rules = []
        }
      },
    },
  },
}
</script>
