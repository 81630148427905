<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="500"
    content-class="cardBg dialogBuyNumber pa-15 pa-sm-20"
    transition="scroll-y-transition"
    overlay-color="overlayColor"
  >
    <v-card class="cardBg" outlined>
      <v-form ref="form" v-model="validateForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-0 pr-sm-11 pb-16"
            cols="12" sm="5">
            <span class="font-large-title text-uppercase grayLighten3--text">Change country</span>
          </v-col>
          <v-col cols="12" sm="7"></v-col>
  
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="5"
          >
            <span class="font-normal nameField--text text-sm-right">Country</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="buyerDialogCountry"
            ></portal-target>
          </v-col>
  
          <v-col class="pb-8 pb-sm-10" cols="12" sm="7">
            <AutocompleteField
              class="field-container"
              v-model="formData.country"
              :items="countries"
              item-text="name"
              item-value="id"
              :rules="rules"
              checkBg
            >
              <template #message="{ key, message }">
                <portal to="buyerDialogCountry" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
          </v-col>
        </v-row>
        
        <v-card-actions class="justify-end pr-0 pt-0 mt-16">
          <ActionButton class="mr-5 mr-sm-10" :loading="loadingAction" @click="submitForm">Save</ActionButton>
          
          <CancelButton @click="closeDialog">Cancel</CancelButton>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import AutocompleteField from '@/components/select/AutocompleteField'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

import { mapState } from 'vuex'

import { updateNumber } from '@/api/number-routes'

export default {
  name: 'DialogChangeCountry',
  inheritAttrs: false,
  components: { AutocompleteField, ActionButton, CancelButton },
  props: {
    value:  {
      type: Boolean,
      default: false
    },
    editNumber: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    loadingAction: false,
    formData: {
      country: null,
    },
    validateForm: true,
    rules: [],
  }),
  computed: {
    ...mapState({
      countries: state => state.countries,
    }),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    async submitForm() {
      await this.validationForm()

      if (this.$refs.form.validate()) {
        this.loadingAction = true

        const { success, payload, message } = await updateNumber({ numberId: this.editNumber.id, formData: this.formData })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('actionSuccess', payload)
          this.closeDialog()
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          if (this.editNumber?.country_id) {
            this.formData.country = this.editNumber['country_id']
          }
        } else {
          this.formData.country = null
          this.rules = []
        }
      }
    }
  },
}
</script>
